import { API, graphqlOperation } from 'aws-amplify';
import { createUserProfile, updateUserProfile, deleteUserProfile } from '../graphql/mutations';
import { getUserProfile } from '../graphql/queries';
import { 
    UserProfile,
    CreateUserProfileInput,
    GetUserProfileQuery,
    UpdateUserProfileInput } from '../API';
import { CleanModel } from './Helpers';

export const CreateUserProfile = async (newUserProfile: CreateUserProfileInput) => {
    try {
        await API.graphql(graphqlOperation(createUserProfile, {input: newUserProfile}))
    } catch (error) {
        throw error;
    }
}

export const GetUserProfile = async (userId: string): Promise<UserProfile | null> => {
    try {
        const response = (await API.graphql(graphqlOperation(getUserProfile, {userId: userId}))) as {
            data: GetUserProfileQuery;
        };

        if (response.data.getUserProfile) {
            return response.data.getUserProfile;
        }
        else {
            return null;
        }
    } catch (error) {
        throw error;
    }
}

export const UpdateUserProfile = async (updatedUserProfile: UpdateUserProfileInput) => {

    try {
        // var userProfile = {
        //     userId: updatedUserProfile.userId,
        //     defaultLocation: {
        //         latitude: updatedUserProfile.defaultLocation.latitude,
        //         longitude: updatedUserProfile.defaultLocation.longitude,
        //         zoom: updatedUserProfile.defaultLocation.zoom
        //     }            
        // };

        // Clean model from potential unwanted GraphQL attributes
        CleanModel(updatedUserProfile);

        await API.graphql(graphqlOperation(updateUserProfile, {input: updatedUserProfile}))
    } catch (error) {
        throw error;
    }
}

export const DeleteUserProfile = async (userId: string) => {

    try {
        const userProfile = {
            userId: userId,
        };
        
        await API.graphql({ query: deleteUserProfile, variables: {input: userProfile}});
    } catch (error) {
        throw error;
    }
}