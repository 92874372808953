import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/Layout'
import { Authenticator, Heading, useTheme } from '@aws-amplify/ui-react';
import { Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import HomePage from './pages/HomePage';

const formFields = {
  confirmVerifyUser: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

const components = {
  VerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Typography>Footer Information</Typography>;
    },
  },

  ConfirmVerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Typography>Footer Information</Typography>;
    },
  },
};

export default function App() {

  return (
    <BrowserRouter>    
      <Authenticator formFields={formFields} components={components} hideSignUp={true} loginMechanisms={['username']}>        
        {({ signOut, user }) => (
          <Layout>
            <Routes>
                <Route path='/' element={<HomePage />} />
                {/* <Route path="/parties" element={<PartiesPage />} />
                <Route path="/editparty/:id" element={<EditPartyPage />} />
                <Route path="/gateways" element={<GatewaysPage />} /> */}
                {/* <Route path="/gateway/:id" element={<GatewayPage />} /> */}
                {/* <Route path="/editgateway/:gatewayEUI" element={<EditGatewayPage />} />
                <Route path="/devices" element={<DevicesPage />} />
                <Route path="/device/:devEUI" element={<DevicePage />} />                
                <Route path="/editdevice/:devEUI" element={<EditDevicePage />} />
                <Route path="/thresholds" element={<EnvironmentalTelemetryThresholdsPage />} />
                <Route path="/editthreshold/:id" element={<EditEnvironmentalTelemetryThresholdsPage />} />
                <Route path="/surveillanceareas" element={<SurveillanceAreasPage />} />
                <Route path="/editsurveillancearea/:id" element={<EditSurveillanceAreaPage />} /> */}
            </Routes>
          </Layout>
        )}        
      </Authenticator>      
    </BrowserRouter>
  );
}