import React, { useEffect, useState, useRef } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom'
import { createMap } from "maplibre-gl-js-amplify";
import { Marker, Map, LngLat } from 'maplibre-gl';
import "maplibre-gl/dist/maplibre-gl.css";
import Button from '@mui/material/Button';
//import { onCreateSensorValues } from '../../graphql/subscriptions';
import { CreateUserProfile, GetUserProfile, UpdateUserProfile } from '../../api/UserProfileApi';
import { 
    CreateUserProfileInput,
    UpdateUserProfileInput } from '../../API';

import './index.css'

// interface ISensorsSubscriptionResponse {
//     value: {
//       data: {
//         onCreateSensorValues: {
//           devEUI: string,
//           status: number
//         }
//       }
//     }
// }

const userProfileState: UpdateUserProfileInput = {
    id: "",
    userId: "",
    userName: "",
    // defaultLocation: {
    //     latitude: 0.0,
    //     longitude: 0.0,
    //     zoom: 0.0
    // },
    // favoriteLocations: []
};

const defaultMapLocationState = {
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0.0
};

const HomePage: React.FC = () => {

    const navigate = useNavigate();

    const { user } = useAuthenticator((context) => [context.user]);
    const [ userProfile, setUserProfile ] = useState<UpdateUserProfileInput>(userProfileState);
    
    const mapRef = useRef(null); // Reference to the map DOM element
    const [map, setMap] = useState<Map>();
    const [ mapDefaultLocation, setMapDefaultLocation ] = useState(defaultMapLocationState);

    const handleClickSetDefaultLocation = async () => {

        // Get the updated default location before updating the user profile
        // if (userProfile.defaultLocation != undefined) {
        //     userProfile.defaultLocation.latitude = mapDefaultLocation.latitude;
        //     userProfile.defaultLocation.longitude = mapDefaultLocation.longitude;
        //     userProfile.defaultLocation.zoom = mapDefaultLocation.zoom;
        // }
        
        // Call api to update the user profile        
        await UpdateUserProfile(userProfile);
    };

    const handleClickGotoDefaultLocation = async() => {
        
        if (mapDefaultLocation != undefined) {
            map?.setCenter(new LngLat(mapDefaultLocation.longitude, mapDefaultLocation.latitude));
            map?.setZoom(mapDefaultLocation.zoom);
        }
    }

    useEffect(() => {
        
        let map: Map;
        let mapStartLatitude = 0.0;
        let mapStartLongitude = 0.0;
        let zoom = 1;

        // function gatewayClicked (gwEUI: string){
        //     var gatewayImage = document.getElementById('gateway-image-' + gwEUI);
    
        //     if (gatewayImage) {
        //         navigate('/gateway/' + gwEUI)                
        //         console.log(gwEUI + ' clicked');
        //     }
        // }

        // function deviceClicked (devEUI: string){
        //     var deviceImage = document.getElementById('device-image-' + devEUI);
    
        //     if (deviceImage) {
        //         navigate('/device/' + devEUI)                
        //         console.log(devEUI + ' clicked');
        //     }
        // }

        // Call api to get the default location set for the logged in user
        async function getMapStartCoordinates(userId: string){

            const userProfile = await GetUserProfile(userId);
    
            if (userProfile) {
    
                console.log('User profile retrived for ' + userId);
                
                // mapStartLatitude = userProfile.defaultLocation?.latitude as number;
                // mapStartLongitude = userProfile.defaultLocation?.longitude as number;
                // zoom = userProfile.defaultLocation?.zoom as number;

                setUserProfile(userProfile);

                setMapDefaultLocation({
                    latitude: mapStartLatitude,
                    longitude: mapStartLongitude,
                    zoom: zoom
                });
            }
            else {
                var newUserProfile: CreateUserProfileInput = {
                    userId: userId,
                    userName: "",
                    // defaultLocation: {
                    //     latitude: mapStartLatitude,
                    //     longitude: mapStartLongitude,
                    //     zoom: zoom
                    // },
                    // favoriteLocations: []
                };

                await CreateUserProfile(newUserProfile);
            }
        }

        // // Call api to fetch list of gateways and display them as markers on the map
        // async function  fetchGateways(map: Map){

        //     const gateways = await GetGateways();
    
        //     if (gateways && gateways.length > 0) {
    
        //         console.log(gateways.length.toString() + ' gateway(s) retrived');
                
        //         gateways.forEach( (gateway) => {
    
        //             var marker = GetGatewayMarker(gateway.gatewayEUI as string, gateway.status as string, () => gatewayClicked(gateway.gatewayEUI as string)) 
    
        //             if (gateway.location != undefined) {
        //                 new Marker(marker)
        //                     .setLngLat([gateway.location.longitude as number, gateway.location.latitude as number])
        //                     .addTo(map);
        //             }
        //         })
        //     }
        // }

        // Call api to fetch list of devices and display them as markers on the map
        // async function  fetchDevices(map: Map){

        //     const devices = await GetDevices();
    
        //     if (devices && devices.length > 0) {
    
        //         console.log(devices.length.toString() + ' device(s) retrived');
                
        //         devices.forEach( (device) => {
    
        //             var marker = GetDeviceMarker(device.devEUI as string, device.status as string, () => deviceClicked(device.devEUI as string)) 
    
        //             if (device.location != undefined) {
        //                 new Marker(marker)
        //                     .setLngLat([device.location.longitude as number, device.location.latitude as number])
        //                     .addTo(map);
        //             }
        //         })
        //     }
        // }

        // Configure and display the map
        async function initializeMap() {

            try {

                if (mapRef.current != null) {

                    await getMapStartCoordinates(user.username as string);

                    //draw map
                    map = await createMap({
                        container: mapRef.current,
                        center: [mapStartLongitude, mapStartLatitude],
                        zoom: zoom, 
                        maxZoom: 20
                    });

                    setMap(map);
    
                    map.repaint = true;

                    map.on("dragend", function() {                   

                        setMapDefaultLocation({
                            latitude: map.getCenter().lat,
                            longitude: map.getCenter().lng,
                            zoom: map.getZoom()
                        });                   
                    })

                    map.on("zoomend", function() {                   

                        setMapDefaultLocation({
                            latitude: map.getCenter().lat,
                            longitude: map.getCenter().lng,
                            zoom: map.getZoom()
                        });                   
                    })

                    console.log('Map Rendered')

                    //await fetchGateways(map)
                    //await fetchDevices(map)
                }
            }
            catch (error) {
                console.log('Error fetching gateways or devices', error);
            }
        }        
        
        initializeMap();
    
        return () => {
            if (map) {
                map.remove()
                console.log('map unloaded')
            }
        };

    }, [navigate, setMapDefaultLocation, user.username]);

    // start subscription for sensor status changes and update sensor marker color
    useEffect(() => {

        // @ts-ignore
        // const subscription = DataStore.observe(onCreateSensorValues).subscribe({
        //     next: (response: ISensorsSubscriptionResponse) => {
        //         console.log('sensor subscription value received')
        //         UpdateDeviceMarker(response.value.data.onCreateSensorValues.devEUI, response.value.data.onCreateSensorValues.status)
        //     },
        //     error: (error: any) => console.warn(error),
        //  });

        // @ts-ignore
        // const subscription = API.graphql(graphqlOperation(onCreateSensorValues)).subscribe({
        //     next: (response: ISensorsSubscriptionResponse) => {
        //         console.log('sensor subscription value received')
        //         UpdateDeviceMarker(response.value.data.onCreateSensorValues.sensorId, response.value.data.onCreateSensorValues.status)
        //     },
        //     error: (error: any) => console.warn(error),
        //  });
      
        return () => {
            //subscription.unsubscribe()
            console.log('subscription cancelled')
        };
    }, []);


    return (
        <>
            <Button variant="contained" onClick={handleClickSetDefaultLocation}>Set Default Location</Button>
            <Button variant="contained" onClick={handleClickGotoDefaultLocation}>Goto Default Location</Button>
            <div ref={mapRef} id='map' />
        </>
    )
}

export default HomePage;